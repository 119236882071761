import { BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "..";

var calculateNewVal = function calculateNewVal(val, scalePercent) {
  var newVal = val;
  newVal = val / 100 * scalePercent;
  newVal = newVal < 1 ? 1 : newVal;
  return newVal;
};
/**
 * Calculate the new value for an inline style value and respect the scaling options.
 */


function scaleMobile(_ref, useWidthOfThisContainerId, original, replaceWith) {
  var enabled = _ref.enabled,
      scalePercent = _ref.scalePercent,
      scalePercentVertical = _ref.scalePercentVertical;
  var additionalVerticalScaling = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  if (process.env.PLUGIN_CTX === "pro") {
    var _document$getElementB;

    /* onlypro:start */
    var useScalePercentVertical = +scalePercent + +scalePercentVertical;
    var _window = window,
        innerWidth = _window.innerWidth;
    var width = useWidthOfThisContainerId ? ((_document$getElementB = document.getElementById(useWidthOfThisContainerId)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientWidth) || innerWidth : innerWidth;
    var doChange = enabled && width <= BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER;

    if (doChange) {
      if (replaceWith === undefined) {
        // MobX observable compatibility
        var useOriginal = typeof original.toJS === "function" ? original.toJS() : original; // Calculate new sizes depending on type

        if (typeof useOriginal === "number" && useOriginal > 0) {
          return calculateNewVal(useOriginal, additionalVerticalScaling ? useScalePercentVertical : scalePercent);
        } // Arrays (e.g. padding)


        if (Array.isArray(useOriginal) && useOriginal.map(function (v) {
          return typeof v === "number" && v > 0;
        }).filter(Boolean).length === useOriginal.length) {
          return useOriginal.map(function (v, i) {
            return calculateNewVal(v, [0, 2].indexOf(i) > -1 && additionalVerticalScaling ? useScalePercentVertical : scalePercent);
          });
        }

        return original;
      }

      return replaceWith;
    }
    /* onlypro:end */

  }

  return original;
}

export { scaleMobile };